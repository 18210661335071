import '../Css/movingText.css';

function MovingText(){

    return(
        <div>
                <div className="moving-text-container">
                <div className="moving-text">
                HTML  <span className='mr10'></span>  CSS  <span className='mr10'></span>  JavaScript  <span className='mr10'></span>  TypeScript  <span className='mr10'></span>  React  <span className='mr10'></span>  Vue
      </div>
      <div className="moving-text-bottom">
            Angular <span className='mr10'></span>   PHP  <span className='mr10'></span>  MySQL  <span className='mr10'></span>  WordPress  <span className='mr10'></span>  Shopify  <span className='mr10'></span>  Three.js
      </div>
    </div>
        </div>
    );
}

export default MovingText;