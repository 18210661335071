import React, { useEffect, useRef } from "react";
import '../Css/background.css';
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

function Background() {
    const mountRef = useRef(null);

    useEffect(() => {
        // Configurazione di base
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            1,
            1000
        );
        camera.position.set(0, 0, 60);

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x000000, 0); // Rende lo sfondo trasparente
        mountRef.current.appendChild(renderer.domElement);

        // Illuminazione
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(0, 1, 1).normalize();
        scene.add(directionalLight);

        // Aggiunta delle stelle
        const starGeometry = new THREE.BufferGeometry();
        const starMaterial = new THREE.PointsMaterial({
            color: 0xffffff,
            size: 3, // Dimensione regolata
        });

        const starVertices = [];
        const starCount = 50; // Numero ridotto di stelle

        for (let i = 0; i < starCount; i++) {
            const x = THREE.MathUtils.randFloatSpread(400);
            const y = THREE.MathUtils.randFloatSpread(400);
            const z = THREE.MathUtils.randFloatSpread(400);
            starVertices.push(x, y, z);
        }

        starGeometry.setAttribute(
            'position',
            new THREE.Float32BufferAttribute(starVertices, 3)
        );

        const stars = new THREE.Points(starGeometry, starMaterial);
        scene.add(stars);

        // Caricamento del font e creazione delle lettere "G" e "B"
        const fontLoader = new FontLoader();
        fontLoader.load(
            'https://threejs.org/examples/fonts/helvetiker_bold.typeface.json',
            (font) => {
                const textOptions = {
                    font: font,
                    size: 20, // Aumentata la dimensione per maggiore visibilità
                    height: 5,
                    curveSegments: 12,
                    bevelEnabled: true,
                    bevelThickness: 2,
                    bevelSize: 1,
                    bevelOffset: 0,
                    bevelSegments: 5,
                };

                const textMaterial = new THREE.MeshPhongMaterial({
                    color: 0xffffff, // Colore bianco per le lettere
                    shininess: 100,
                });

                const textGeometryG = new TextGeometry('G', textOptions);
                const textMeshG = new THREE.Mesh(textGeometryG, textMaterial);
                textMeshG.position.set(-25, -10, 0); // Spostato più a sinistra
                scene.add(textMeshG);

                const textGeometryB = new TextGeometry('B', textOptions);
                const textMeshB = new THREE.Mesh(textGeometryB, textMaterial);
                textMeshB.position.set(15, -10, 0); // Spostato più a destra
                scene.add(textMeshB);
            }
        );

        // Animazione
        const animate = () => {
            requestAnimationFrame(animate);
            stars.rotation.y += 0.0005;
            renderer.render(scene, camera);
        };

        animate();

        // Gestione del resize
        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Pulizia
        return () => {
            window.removeEventListener('resize', handleResize);
            if (mountRef.current) {
                mountRef.current.removeChild(renderer.domElement);
            }
            renderer.dispose();
        };
    }, []);

    return (
        <div
            ref={mountRef}
            className="bg"
        />
    );
}

export default Background;
