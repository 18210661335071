import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import './index.css';
import App from './App';
import Background from './Pages/Background';
import reportWebVitals from './reportWebVitals';
import Header from './Pages/Header';
import Animate from './Pages/Animate';
import Footer from './Pages/Footer';
import MovingText from './Pages/MovingText';

const Works = React.lazy(() => import('./Pages/Works'));
const Contact = React.lazy(() => import('./Pages/Contact'));
const Cv = React.lazy(() => import('./Pages/Cv'));

const PageWrapper = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<PageWrapper><App /></PageWrapper>} />
        <Route path="/Works" element={<PageWrapper><Works /></PageWrapper>} />
        <Route path="/Contact" element={<PageWrapper><Contact /></PageWrapper>} />
        <Route path="/Cv" element={<PageWrapper><Cv /></PageWrapper>} />
      </Routes>
    </AnimatePresence>
  );
};
const Root = () => {
  const [showAnimate, setShowAnimate] = useState(false);

  useEffect(() => {
    const animateShown = localStorage.getItem('animateShown');

    if (!animateShown) {
      // Se l'animazione non è stata mostrata, mostrala
      setShowAnimate(true);
      localStorage.setItem('animateShown', 'true');
    } else {
      // Se l'animazione è già stata mostrata, non mostrarla
      setShowAnimate(false);
    }

    const handleUnload = () => {
      localStorage.removeItem('animateShown');
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const handleAnimateComplete = () => {
    setShowAnimate(false);
  };

  return (
    <>
      <BrowserRouter>
        <>
          <MovingText />
          <Header />
          <Background />
          <Footer />
          <React.Suspense fallback={<></>}>
            <AnimatedRoutes />
          </React.Suspense>
        </>
      </BrowserRouter>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);

reportWebVitals();
