import React from "react";
import '../Css/home.css';

function Home() {


    return (
        <div className="border">
            <div className="mainHome">
                <div className="textHome">
                   <span><h3>Born in 2003 in Foligno, Italy. <br></br> I studied Electronics for 5 years and then <br></br> I realized that programming is what I like the most <br></br> and I am always ready to discover new things</h3></span>
                   <h3>"If you can dream, you can do it"</h3>
                </div>
            </div>
        </div>
    );
}

export default Home;