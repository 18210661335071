import React from "react";
import { NavLink } from "react-router-dom";
import '../Css/header.css';

function Header() {
    return (
        <div className="background">
            <div className="inline">
                <div className="logo">
                    <NavLink to="/" className="noUnderline">
                        <h1>Giulio Borzetta</h1>
                    </NavLink>
                    <h4>Full Stack Web Developer</h4>
                </div>

                <div className="info">
                    <span>

                    <NavLink
                        to="/Works"
                        className={({ isActive }) => `index ${isActive ? "clicked" : ""}`}
                    >
                        Works
                    </NavLink>
                    </span>
                    <span>
                    <NavLink
                        to="/cv"
                        className={({ isActive }) => `index ${isActive ? "clicked" : ""}`}
                    >
                        CV
                    </NavLink>
                    </span>
                    <span>
                    <NavLink
                        to="/Contact"
                        className={({ isActive }) => `index ${isActive ? "clicked" : ""}`}
                    >
                        Contact
                    </NavLink>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Header;
