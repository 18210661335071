import './App.css';
import Home from './Pages/home';

function App() {
  return (
    <div className="App">
        <Home></Home>
    </div>
  );
}

export default App;
