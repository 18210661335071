import React from "react";
import '../Css/footer.css';

function Footer() {
    return(
        <div className="footer">
            <h4>Copyright 2024 - By Giulio Borzetta</h4>
        </div>
    );
}

export default Footer